$(document).ready(function() {
    $('select.select2').select2();

  $('.orders__select-main select').select2({
    minimumResultsForSearch: -1
  });

  $('.table__slide').click(function() {
    $(this)
      .toggleClass('active')
      .closest('.orders__table-row').toggleClass('active')
      .closest('.orders__table-row').find('.orders__table-drop-wrapper').slideToggle();
    return false;
  });

  $('.orders__table-row').click(function() {
    $(this)
      .toggleClass('active')
      .find('.table__slide').toggleClass('active')
    $(this).find('.orders__table-drop-wrapper').slideToggle()
  });


  $('.orders__table-row a').click(function(e) {
    e.stopPropagation();
  });

  $('.table__slide-mobile').click(function() {
    $(this)
      .toggleClass('active')
      .closest('.orders__table-mobile-item').toggleClass('active')
      .closest('.orders__table-mobile-item').find('.orders__table-mobile-drop-wrapper').slideToggle();
    return false;
  });

  $(window).scroll(function() {
    var height = $(window).scrollTop();

        if (height > 100) {
            $('.header').addClass('header-fixed');
        } else {
            $('.header').removeClass('header-fixed');
        }
    });

    if ($(window).width() > 980) {
        $('.header__nav-item--drop').mouseenter(function() {
            $('.header__nav-dropdown').fadeIn(200);
        });

        $('.header__nav-item--drop').mouseleave(function() {
            $('.header__nav-dropdown').fadeOut(200);
        });
    }

    $('.header__hamburger').click(function() {
        $('.header__nav').slideToggle();
    });

    $('a.action_download').on( "click", function(e) {
        e.preventDefault();
        const form = $('#filter-form');
        form.find('input[name="download"]').val("1")

        form.submit();
    });

    $('button.action_filter').on( "click", function() {
        const form = $('#filter-form');
        form.find('input[name="download"]').val("0")
    });

    $('a.action_download_order').on("click", function(e) {
        e.preventDefault();
        const form = $(this).closest('.orders__table-actions').find('.action_download_form');
        form.submit();
    });


    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    $('a.action_update_order').on("click", function(e) {
        e.preventDefault();

        let url = $(this).attr('href');
        let statusField = $(this).closest('.orders__table-row').find('.status_text')
        $.ajax({
            type:'GET',
            url:url,
            success:function(order){
                let status = order.status;
                statusField.text(status);
            }
        });
    });

});
